$alert-info: (
    background: color(light),
    border: rem-calc(1) solid color(border),
    color: color(primary),
    icon: 'info',
    icon-color: color(primary)
);

$alert-warning: (
    background: color(transparent),
    border: none,
    color: color(warning),
    icon: 'alert',
    icon-color: color(dark)
);

$alert-success: (
    background: color(transparent),
    border: none,
    color: color(text),
    icon: 'success',
    icon-color: color(success)
);

$alert-error: (
    background: color(global-background),
    border: none,
    color: color(text),
    icon: 'error',
    icon-color: color(alert)
);

$alert-box-shadow: null;
$alert-border-radius: 0;
$alert-primary-has-border: true;

@import '@lora/05-components/alert';