$form-text-field-border: 0;
$form-text-field-border-hover: 0;
$form-text-field-border-focus: 0;
$form-text-field-padding: rem-calc(15 0 15 10);
$form-text-field-height: rem-calc(50);
$form-text-field-font-size: rem-calc(14);
$form-text-field-box-shadow: rem-calc(0 1 0 0) color(dark);
$form-text-field-box-shadow-hover: rem-calc(0 1 0 0) color(dark);
$form-text-field-box-shadow-focus: rem-calc(0 1 0 0) color(dark);
$form-text-field-textarea-counter-float-padding: rem-calc(10 0);
$form-text-field-textarea-padding: rem-calc(10 0);

@import "@lora/05-components/form/text-field";