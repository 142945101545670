$carousel-arrow-border-radius: 50%;

$carousel-pagination-bullet-size: rem-calc(6);
$carousel-pagination-bullet-horizontal-margin: rem-calc(5);
$carousel-pagination-bullet-size--large: rem-calc(15);
$carousel-pagination-bullet-horizontal-margin--large: rem-calc(8 7);
$carousel-pagination-bullet-color: color(border);

$carousel-autoplay-button-color: color(dark);

// Arrows
$carousel-arrow-sizes: (
    default: (
        arrow-width: rem-calc(34),
        arrow-height: rem-calc(34),
        arrow-icon-width: rem-calc(34),
        arrow-icon-height: rem-calc(34),
        arrow-icon: chevron-right
    ),
    small: (
        arrow-width: rem-calc(15),
        arrow-height: rem-calc(15),
        arrow-icon-width: rem-calc(15),
        arrow-icon-height: rem-calc(15),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(15),
        arrow-height: rem-calc(15),
        arrow-icon-width: rem-calc(15),
        arrow-icon-height: rem-calc(15),
        arrow-icon: chevron-right
    )
);

$carousel-arrow-theme: (
    light: (
        background: transparent,
        color: color(primary)
    ),
    dark: (
        background: transparent,
        color: color(light)
    )
);

$carousel-bullet-sizes: (
    default: (
        bullet-size: $carousel-pagination-bullet-size,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin
    ),
    small: (
        bullet-size: rem-calc(6),
        bullet-margin: rem-calc(0 10)
    )
);

$carousel-bullet-sizes--large: (
    default: (
        bullet-size: $carousel-pagination-bullet-size--large,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin--large
    ),
    small: (
        bullet-size: rem-calc(6),
        bullet-margin: rem-calc(0 7)
    )
);

@import "@lora/05-components/carousel";