$footer-background: color(footer-secondary);
$footer-background--large: $footer-background;
$footer-top-background: color(footer-top-primary);
$footer-top-background--large: $footer-top-background;
$footer-top-border-top: rem-calc(1) solid color(border);
$footer-newsletter-signup-title-font: #{rem-calc(18)} $font-primary;
$footer-newsletter-signup-title-color: color(dark);
$footer-newsletter-signup-title-color--large: $footer-newsletter-signup-title-color;
$footer-newsletter-color: color(dark) !default;
$footer-newsletter-color--large: color(dark);
$footer-bottom-inner-border-top--large: rem-calc(1) solid color(border);
$footer-bottom-border-top--large: none;
$footer-newsletter-margin: rem-calc(52 0 0);
$footer-social-title-color: color(dark);
$footer-social-margin: rem-calc(0 -10);
$footer-social-margin--large: rem-calc(20 0 16 0);
$footer-newsletter-link-color: color(dark);
$footer-newsletter-link-color--large: color(dark);
$footer-newsletter-link-hover-color: color(dark);
$footer-newsletter-signup-title-display: none;
$footer-newsletter-signup-title-display--large: block;
$footer-newsletter-captcha-link-hover-color: inherit;
$footer-contacts-title-color: color(dark);
$footer-contacts-title-color--large: $footer-contacts-title-color;

@import "@lora/04-layout/footer";