$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-info-width: 60%;
$product-tile-horizontal-info-item-font-weight--large: 500;
$product-tile-horizontal-variation-margin--large: rem-calc(15 0);
$product-tile-horizontal-variation-font: 100 #{rem-calc(12)} / rem-calc(14) $font-primary;
$product-tile-horizontal-variation-font--large: $product-tile-horizontal-variation-font;
$product-tile-horizontal-name-text-transform: uppercase;
$product-tile-horizontal-secondary-quantity-width--large: rem-calc(98);
$product-tile-horizontal-name-font-weight: 900;
$product-tile-horizontal-subtitle-color: color(organism-background);
$product-tile-horizontal-name-font-family: $font-secondary;
$product-tile-horizontal-info-item-font: 500 rem-calc(14) $font-tertiary;
$product-tile-horizontal-figure-margin: rem-calc(0 10 0 0);
$product-tile-horizontal-figure-margin--large: rem-calc(0 30 0 0);
$product-tile-horizontal-secondary-figure-width: rem-calc(60);
$product-tile-horizontal-secondary-figure-width--large: rem-calc(170);
$product-tile-horizontal-variations-padding--large: rem-calc(0 0 0 22);
$product-tile-horizontal-name-max-lines: 2;

@import "@lora/05-components/product/product-tile-horizontal";