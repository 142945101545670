$header-navigation-link-level-1-font-family: $font-secondary;
$header-navigation-link-level-1-color: color(dark);
$header-navigation-link-level-1-font-size: rem-calc(14);
$header-navigation-link-level-1-font-family--large: $font-secondary;
$header-navigation-link-level-1-color--large: color(dark);
$header-navigation-link-level-1-font-size--large: rem-calc(12) !default;
$header-navigation-link-level-1-text-transform: none;
$header-navigation-link-level-1-text-transform--large: uppercase;
$header-navigation-item-level-1-margin--large: rem-calc(0 5 0 8);
$header-navigation-link-level-1-padding--large: rem-calc(17 16 14);
$header-navigation-link-level-2-font-size: rem-calc(14);
$header-navigation-item-title-level-1-padding-left: rem-calc(24);
$header-navigation-item-title-level-1-expanded-background: color(light);
$header-navigation-item-title-level-1-expanded-color: color(primary);
$header-navigation-link-level-1-hover-color--large: color(primary-active);
$header-navigation-item-title-level-2-font-weight: normal;
$header-navigation-item-title-level-2-font-weight--large: normal;
$header-navigation-item-title-level-2-background: color(light);
$header-navigation-item-title-level-2-padding-left: rem-calc(40);
$header-navigation-item-title-level-2-expanded-background: color(light);
$header-navigation-item-title-level-1-expanded-font: #{rem-calc(12)} / 14px $font-primary;
$header-navigation-item-title-level-2-expanded-color: color(primary);
$header-navigation-item-title-level-3-background: color(light);
$header-navigation-item-level-2-padding--large: rem-calc(44 0 5);
$header-navigation-item-level-2-columns--large: 6;
$header-navigation-item-level-2-width--large: (100% / $header-navigation-item-level-2-columns--large);
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-link-level-3-font-weight: normal;
$header-navigation-link-level-3-font-size: rem-calc(14);
$header-navigation-link-level-3-font-size--large: rem-calc(14);
$header-navigation-link-level-3-padding--medium-down: rem-calc(10 0 10 50);
$header-navigation-link-level-3-color: color(text-secondary);
$header-navigation-link-level-3-color--large: color(text-secondary);
$header-navigation-link-level-3-hover-font-weight: normal;
$header-navigation-item-level-3-all-link-font--large: #{rem-calc(14)} / 1.6 $font-primary;
$header-navigation-item-level-3-all-link-text-decoration--large: underline;
$header-navigation-list-level-3-margin--large: rem-calc(25 0 0);
$header-navigation-flyout-element-border-top--large: 1px solid color(organism-background);
$header-navigation-flyout-element-border-bottom--large: $header-navigation-flyout-element-border-top--large;
$header-navigation-flyout-element-border-top--large: $header-navigation-flyout-element-border-top--large;
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-flyout-element-margin-left--large: rem-calc(-23);
$header-navigation-link-level-2-color: color(dark);
$header-navigation-link-level-2-font-family: $font-tertiary;
$header-navigation-link-level-2-font-size: rem-calc(12);
$header-navigation-link-level-2-font-weight: null;
$header-navigation-link-level-2-font-weight--large: 900;
$header-navigation-link-level-2-hover-font-weight: 900;
$header-navigation-link-level-2-hover-color: color(primary-active);
$header-navigation-link-level-2-text-transform: uppercase;
$header-navigation-item-level-3-all-link-color--large: color(dark);
$header-navigation-item-title-level-3-color--large: color(text-secondary);
$header-navigation-link-level-3-color--large: color(text-secondary);
$header-navigation-item-level-3-padding--large: rem-calc(5 0);
$header-navigation-list-level-2-padding--large: rem-calc(15 0 40);
$header-navigation-content-title-font--large: $font-tertiary;
$header-navigation-content-title-margin--large: rem-calc(16 0 0);
$header-navigation-content-title-text-transform--large: uppercase;
$header-navigation-link-level-3-hover-color: color(text);
$header-navigation-content-hover-color--large: color(primary-active);
$header-navigation-link-level-1-hover-color: color(primary-active);
$header-navigation-content-hover-color--large: color(primary-active);
$header-navigation-link-level-1-font-weight: 700;

@import "@lora/05-components/header/header-navigation";