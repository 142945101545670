$error-callout-text-align: left;
$error-callout-text-align--large: left;
$error-callout-title-font: 900 #{rem-calc(26)} / 1 $font-secondary;
$error-callout-title-font--large: 900 #{rem-calc(36)} / 1 $font-secondary;
$error-callout-title-color: color(secondary);
$error-callout-title-color--large: color(secondary);
$error-callout-title-text-transform: none;

$error-callout-subtitle-font: 300 #{rem-calc(12)} / #{rem-calc(20)} $font-primary;
$error-callout-subtitle-font--large: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$error-callout-subtitle-text-transform: none;
$error-callout-subtitle-color: color(secondary);
$error-callout-subtitle-color--large: color(secondary);
$error-callout-color: color(secondary);
$error-callout-color--large: color(secondary);
$error-callout-padding: rem-calc(150 20 20 20);
$error-callout-padding--large: rem-calc(99 15);

$error-callout-title-text-align: left;
$error-callout-title-text-align--large: left;
$error-callout-subtitle-text-align: left;
$error-callout-subtitle-text-align--large: left;
$error-callout-info-text-align: left;
$error-callout-info-text-align--large: left;

.c-error-callout {
    @include breakpoint(large) {
        @include layout;
    }
}
/* stylelint-disable no-invalid-position-at-import-rule */
@import "@lora/05-components/error-callout";
/* stylelint-enable no-invalid-position-at-import-rule */