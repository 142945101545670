@import "@lora/03-base/colors";

$palette-custom: (
    light:     #fff, // White
    dark:      #333,// Black
);
$palette: map-extend($palette-default, $palette-custom);

$semantic-colors-custom: (
    primary:             #dcc7b7,
    primary-active:      #b69679,
    secondary:           #fff,
    secondary-active:    #fff,
    tertiary:            #a83759,
    text:                #333,
    text-secondary:      #333,
    success:             #336837,
    warning:             #b69679,
    alert:               #c93838,
    disabled:            #ccc,
    element-background:  #f6f1ed,
    global-background:   #fff,
    organism-background: #b69679,
    border:              #d8d8d8,
    overlay:             rgba(color(dark), 0.5),
    light-opacity:       rgba(color(light), 0.5),
    footer-secondary:    #bb9775,
    footer-top-primary:  #f4ede7,
);
$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
