$product-tile-name-color: color(dark);
$product-tile-name-font-family: $font-secondary;
$product-tile-description-color: color(text);
$product-tile-description-font-size: rem-calc(12);
$product-tile-description-font-size--large: rem-calc(12);
$product-tile-name-margin--large: rem-calc(5) 0 0;
$product-tile-name-text-transform: uppercase;
$product-tile-info-item-align: center;
$product-tile-info-item-align--large: $product-tile-info-item-align;
$product-tile-info-margin: rem-calc(16) 0 0;
$product-tile-info-margin--large: rem-calc(22) 0 0;
$product-tile-variations-width--large: 100%;
$product-tile-add-bag-button-min-width: 100%;
$product-tile-add-bag-button-min-width--large: $product-tile-add-bag-button-min-width;
$product-tile-name-text-transform: uppercase;
$product-tile-info-separator: none;
$product-tile-badge-text-transform: uppercase;
$product-tile-badge-font: #{rem-calc(12)} $font-secondary;
$product-tile-badge-color: color(primary);
$product-tile-badge-left: rem-calc(7);
$product-tile-badge-left--large: rem-calc(20);
$product-tile-badge-top: rem-calc(2);
$product-tile-badge-top--large: rem-calc(2);
$product-tile-variation-group-height: rem-calc(50);
$product-tile-variation-group-height--large: rem-calc(50);
$product-tile-thumbnail-margin: rem-calc(16);
$product-tile-thumbnail-width: auto;
$product-tile-name-line-height: 1.4;
$product-tile-name-line-height--large: $product-tile-name-line-height;
$product-tile-grid-list-align-items: flex-start;
$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: $product-tile-grid-name-text-align;
$product-tile-grid-info-item-align-self: initial;
$product-tile-name-font-size: rem-calc(12);
$product-tile-name-font-size--large: rem-calc(14);
$product-tile-price-font: 500 #{rem-calc(14)} $font-tertiary;
$product-tile-price-font--large: $product-tile-price-font;
$product-tile-price-padding: rem-calc(20 0 0 0);
$product-tile-price-padding--large: rem-calc(0 0 0 12);
$product-tile-grid-figure-padding: rem-calc(40 10 0 0);
$product-tile-badge-max-width: 50%;
$product-tile-badge-max-width--large: 40%;
$product-tile-default-text-align: center;
$product-tile-name-color-visited: color(text-secondary);
$product-tile-add-bag-text-transform: none;
$product-tile-add-bag-text-transform--large: none;
$product-tile-thumbnail-on-hover-badge-opacity: hidden;

@import "@lora/05-components/product/product-tile";