// Label
$content-tile-large-label-font: #{rem-calc(12)} / 1.5 $font-primary;
$content-tile-large-label-font--large: #{rem-calc(12)} $font-primary;
$content-tile-label-display: inline-block;
$content-tile-label-display--large: $content-tile-label-display;
$content-tile-label-background: color(primary);
$content-tile-label-background--large: $content-tile-label-background;
$content-tile-label-padding: rem-calc(4 12);
$content-tile-label-padding--large: $content-tile-label-padding;
$content-tile-large-label-color: color(dark);
$content-tile-large-label-color--large: $content-tile-large-label-color;
$content-tile-label-font: 500 #{rem-calc(12)} / 1.25 $font-tertiary;
$content-tile-label-font--large: $content-tile-label-font;

// Title
$content-tile-title-font-size: rem-calc(18);
$content-tile-title-font-size--large: $content-tile-title-font-size;
$content-tile-title-line-height: 1.25;
$content-tile-title-line-height--large: $content-tile-title-line-height;
$content-tile-title-font: #{$content-tile-title-font-size} / $content-tile-title-line-height $font-primary;
$content-tile-title-font--large: #{$content-tile-title-font-size--large} / $content-tile-title-line-height--large $font-tertiary;
$content-tile-title-text-transform: none;
$content-tile-title-text-transform--large: $content-tile-title-text-transform;
$content-tile-large-title-color: color(primary);

// Description
$content-tile-description-font-size: rem-calc(14);
$content-tile-description-font-size--large: $content-tile-description-font-size;
$content-tile-description-line-height: 1.5;
$content-tile-description-line-height--large: $content-tile-description-line-height;
$content-tile-description-font: #{$content-tile-description-font-size} / $content-tile-description-line-height $font-primary;
$content-tile-description-font--large: #{$content-tile-description-font-size--large} / $content-tile-description-line-height--large $font-primary;

$content-tile-title-margin: rem-calc(0 0 20);

// Label Large
$content-tile-large-label-text-transform: uppercase;
$content-tile-large-label-text-transform--large: $content-tile-large-label-text-transform;
$content-tile-large-label-margin: rem-calc(0 0 15);

// Title Large
$content-tile-large-title-text-transform: none;
$content-tile-large-title-text-transform--large: $content-tile-large-title-text-transform;
$content-tile-large-title-font: #{rem-calc(26)} / rem-calc(28) $font-secondary;
$content-tile-large-title-font--large: #{rem-calc(38)} / rem-calc(38) $font-secondary;

// Description Large
$content-tile-large-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-tile-large-description-font--large: $content-tile-large-description-font;
$content-tile-large-description-margin: rem-calc(0 0 35);
$content-tile-title-color: color(dark);
$content-tile-title-color--large: $content-tile-title-color;

$content-tile-label-color: color(secondary);
$content-tile-label-color--large: $content-tile-label-color;

$content-tile-link-color: color(dark);

$content-tile-gutter: (
    default: (
        small: rem-calc(20),
        large: rem-calc(20),
    ),
    large: (
        small: rem-calc(20),
        large: rem-calc(100),
    ),
);

@import "@lora/05-components/content-tile";