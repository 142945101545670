$font-primary: "neuehaasgroteskBook", "Noe-Display", sans-serif;
$font-secondary: "neuehaasgroteskMedium", "Noe-Display", sans-serif;
$font-tertiary: "neuehaasgroteskHeavy", "Noe-Display", sans-serif;

$font-list: (
    ('neuehaasgroteskBook','neuehaasgroteskBook', 400, normal),
    ('neuehaasgroteskMedium','neuehaasgroteskMedium', 700, normal),
    ('neuehaasgroteskHeavy','neuehaasgroteskHeavy', 900, normal)
);

@import "@lora/font-settings";