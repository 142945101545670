$footer-social-title-font--large: #{rem-calc(18)} $font-primary;
$footer-social-link-color: color(text);
$footer-social-link-border-radius: 50%;
$footer-social-link-background: color(light);
$footer-social-list-margin: rem-calc(29 27 35);
$footer-social-list-margin--large: rem-calc(16 0 0);
$footer-social-list-justify-content--large: space-between;
$footer-social-item-margin: rem-calc(0 10 0 0);
$footer-social-item-margin--large: $footer-social-item-margin;
$footer-social-item-title-flex--large: 0 0 100%;
$footer-social-item-title-margin--large: rem-calc(0 0 10 0);
$footer-social-list-flex-wrap--large: wrap;
$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
);

@import "@lora/05-components/footer/footer-social";