// Title
$section-title-font: #{rem-calc(26)} / 1 $font-secondary;
$section-title-font--large: #{rem-calc(36)} / 1.25 $font-secondary;
$section-title-text-transform: none;
$section-title-text-transform--large: none;
$section-title-margin: rem-calc(0 0 15);
$section-title-margin--large: $section-title-margin;
$section-title-text-align: left;

// Secondary title
$section-secondary-title-color: color(dark);
$section-secondary-title-font: #{rem-calc(26)} / 1 $font-secondary;
$section-secondary-title-font--large: $section-title-font--large;
$section-description-font--large: #{rem-calc(14)} / 1.5 $font-primary;

//View more
$section-view-more-link-font: #{rem-calc(12)} / 1.25 $font-primary;
$section-view-more-link-font--large: #{rem-calc(14)} / 1.25 $font-primary;

// Headings
$section-headings-max-width--large: 100%;

// View more
$section-view-more-link-font: #{rem-calc(12)} / rem-calc(20) $font-primary;
$section-view-more-link-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-title-secondary-font: #{rem-calc(26)} / 1.5 $font-secondary;
$section-title-secondary-font--large: $section-title-secondary-font;
$section-view-more-link-text-transform: none;
$section-view-more-link-text-transform--large: none;

@import "@lora/05-components/section";