$footer-nav-link-level1-color: color(dark);
$footer-nav-link-level1-color--large: $footer-nav-link-level1-color;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-link-level1-font--large: 500 #{rem-calc(12)} / 1.16 $font-primary;
$footer-nav-link-level2-color: color(dark);
$footer-nav-link-level2-color--large: $footer-nav-link-level2-color;
$footer-nav-list-level2-margin--large: rem-calc(10 0 0);
$footer-nav-link-level2-font--large: 300 #{rem-calc(12)} / 1.16 $font-primary;
$footer-nav-list-level1-margin: rem-calc(52 0 20);
$footer-nav-list-level1-margin--large: rem-calc(56 0 20);

@import "@lora/05-components/footer/footer-nav";