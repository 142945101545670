$header-user-menu-content-link-font-size: rem-calc(14);
$header-user-menu-content-link-font-family: $font-tertiary;
$header-user-menu-content-link-logout-background: color(primary);
$header-user-menu-content-link-logout-hover-background: color(primary-active);
$header-user-menu-content-item-border-top: 1px solid color(border);
$header-user-menu-content-link-height: rem-calc(50);
$header-user-menu-back-button-height: rem-calc(60);
$header-user-menu-content-link-logout-font-family: $font-tertiary;
$header-user-menu-content-link-logout-margin: rem-calc(20 0);
$header-user-menu-secondary-list-margin: rem-calc(30 60 20);
$header-user-menu-secondary-link-height: rem-calc(50);
$header-user-menu-secondary-link-padding: rem-calc(8 20);
$header-user-menu-secondary-list-border-top: rem-calc(1) solid color(border);
$header-user-menu-title-flex-direction: row;
$header-user-menu-title-margin: rem-calc(0 6 0 0);
$header-user-menu-padding: rem-calc(21 23 20 20);
$header-user-menu-back-button-font: #{rem-calc(12)} / rem-calc(14) $font-tertiary;
$header-user-menu-font: #{rem-calc(14)} / 1.4 $font-primary;
$header-user-menu-justify-content: left;

@import "@lora/05-components/header/header-user-menu";