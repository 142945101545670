$header-sticked-logo-padding--large: rem-calc(0 30 0 0);
$header-border-bottom: color(element-background) rem-calc(1) solid;
$header-sticked-border-bottom: color(element-background) rem-calc(1) solid;
$header-bottom-background--large: color(light);
$header-search-button-color-hover--large: color(light);
$header-sticked-background: color(light);
$header-logo-padding--large: rem-calc(15 0 0);
$header-search-button-background--large: color(element-background);
$header-logo-max-width--large: rem-calc(172);
$header-main-area-padding: rem-calc(0 16 0 0);
$header-main-area-padding--large: 0;
$header-search-button-color--large: color(dark);
$header-search-button-padding--large: rem-calc(0 20 0 19);
$header-search-button-text-transform: capitalize;
$header-search-button-label-active-margin: rem-calc(0 73 0 0);
$header-top-nav-right-padding--large: rem-calc(30 0 0);
$header-top-nav-left-padding: rem-calc(0 22 0 0);
$header-top-nav-left-padding--large: rem-calc(30 0 0);
$header-top-nav-left-align-self--large: center;
$header-top-nav-right-align-self--large: center;
$header-search-button-active-padding--large: rem-calc(0 20 0 19);
$header-top-nav-storeslink-padding: 0;
$header-top-nav-item-hover-color: color(primary-active);
$header-sticked-top-nav-link-title-level-1-hover-color--large: $header-top-nav-item-hover-color;
$header-sticked-top-nav-item-level-1-max-width--large: rem-calc(160);
$header-top-nav-item-font-size--large: rem-calc(12);
$header-top-nav-item-padding--large: rem-calc(0 0 0 12);
$header-top-nav-item-margin--large: rem-calc(0 0 0 15);
$header-search-button-icon-color: color(dark);
$header-search-button-icon-color--large: color(dark);
$header-search-button-label-margin: rem-calc(0 73 0 0);
$header-top-nav-item-separator-color: none;
$header-sticked-minicart-link-margin--large: 0;
$header-sticked-minicart-icon: basket;
$header-sticked-minicart-icon-height: rem-calc(28);
$header-sticked-minicart-icon-width: rem-calc(24);
$header-sticked-top-nav-item-padding--large: rem-calc(0 0 0 10);
$header-search-button-icon-width: rem-calc(18);
$header-search-button-icon-height: rem-calc(19);
$header-sticked-search-button-margin--large: rem-calc(2 -25 0 0);

$header-logo-order: 3;
$header-search-cta-order: 4;
$header-localization-order: 2;
$header-search-button-padding: rem-calc(12 5 12 9);
$header-logo-max-width: rem-calc(99);
$header-logo-padding: rem-calc(10 0);
$header-logo-text-align: center;
$header-logo-justify-content: center;
$header-top-nav-item-color: color(dark);
$header-hamburger-order: 1;

@mixin master-layout-header {
    .l-header.m-sticked {
        @include breakpoint(large) {
            .l-header__top-nav-item.m-stores {
                display: none;
            }
        }
    }
}
/* stylelint-disable no-invalid-position-at-import-rule */
@import '@lora/04-layout/header';
/* stylelint-enable no-invalid-position-at-import-rule */