// Title
$content-hero-title-font: #{rem-calc(26)} / 1.25 $font-secondary;
$content-hero-title-font--large: #{rem-calc(36)} / 1.25 $font-secondary;
$content-hero-title-text-transform: none;
$content-hero-title-text-transform--large: none;
$content-hero-title-color: color(dark);
$content-hero-title-color--large: $content-hero-title-color;
$content-hero-title-margin: rem-calc(5) 0;
$content-hero-title-margin--large: rem-calc(24) 0;
$content-hero-label-color--large: color(secondary);

// Description
$content-hero-description-margin: rem-calc(0 0 25);
$content-hero-description-margin--large: rem-calc(0 0 33);
$content-hero-description-color: color(dark);
$content-hero-description-color--large: $content-hero-description-color;
$content-hero-description-font: #{rem-calc(12)} / #{rem-calc(14)} $font-primary;
$content-hero-description-font--large: #{rem-calc(14)} / #{rem-calc(20)} $font-primary;

@import "@lora/05-components/content/content-hero";