$form-check-field-label-font-size: rem-calc(14);

// Radiobuttons
$form-check-field-radio-control-background: color(tertiary);
$form-check-field-radio-control-after-top: rem-calc(1);
$form-check-field-radio-control-after-left: rem-calc(-2);
$form-check-field-radio-control-margin-top: rem-calc(-7);
$form-check-field-radio-checked-control-border-after: rem-calc(1) solid color(tertiary);
$form-check-field-radio-control-after-border: rem-calc(1) solid color(border);
$form-check-field-radio-control-size: rem-calc(14);

$form-check-field-checkbox-checked-icon-position--top: rem-calc(5);
$form-check-field-disabled-control-background: color(light);
$form-check-field-error-control-color: color(alert);
$form-check-field-disabled-control-color: color(disabled);
$form-check-field-disabled-control-border-color: color(border);
$form-check-field-checkbox-checked-icon: check;
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-icon-width: rem-calc(15);
$form-check-field-checkbox-checked-icon-height: rem-calc(18);
$form-check-field-checkbox-checked-icon-position--top: rem-calc(1);
$form-check-field-checkbox-checked-icon-position--left: rem-calc(2);
$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-checkbox-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-control-font-weight: bold;

@import "@lora/05-components/form/check-field";