$button-color: color(dark);
$button-disabled-background: color(disabled);
$button-disabled-color: color(dark);
$button-hover-color: color(dark);
$button-text-transform: none;
$button-padding: rem-calc(17 18);
$button-font-family: $font-tertiary;

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook-2,
        background: #35599e,
        background-hover: #1674d1,
        color: color(light),
        color-hover: null,
        border: null,
    ),
    google: (
        icon: google-2,
        background: color(light),
        background-hover: null,
        color: #000,
        color-hover: null,
        border: 1px solid #ddd,
    )
) !default;

$button-primary: (
    background: color(primary),
    background-hover: color(secondary),
    color: color(primary),
    color-hover: color(primary-active),
    border: 1px solid color(border),
    border-hover: 1px solid color(primary-active)
);

$button-secondary: (
    background: color(secondary),
    background-hover: color(light-opacity),
    color: color(dark),
    color-hover: color(dark),
    border: 1px solid color(border),
    border-hover: 1px solid color(border)
);

$button-alert: (
    background: color(alert),
    color: color(light)
);

$button-palette: (
    secondary: $button-secondary,
    alert: $button-alert
);

$button-sizes: (
    tiny: (
        padding: rem-calc(5 12 4),
        font-size: rem-calc(12),
    ),
    small: (
        padding: rem-calc(8 12),
        font-size: rem-calc(12),
    ),
    large: (
        padding: rem-calc(12),
        font-size: rem-calc(14),
    ),
) !default;

$button-font-size: rem-calc(14);
$button-print-text-transform: none;
$button-print-text-decoration: none;
$button-alt-background: color(primary);
$button-alt-color: color(dark);

@import "@lora/05-components/button";