// Close icon
$modal-close-icon-color: color(dark);

// Title
$modal-title-font: #{rem-calc(26)} / 1.25 $font-secondary;
$modal-title-font--large: normal #{rem-calc(36)} / 1.25 $font-secondary;
$modal-title-text-transform: none;
$modal-title-text-transform--large: none;

// Text
$modal-text-font-size: rem-calc(14);
$modal-text-large-font-size: $modal-text-font-size;
$modal-text-line-height: 1.4;
$modal-text-margin: rem-calc(0 0 50);
$modal-body-font: #{rem-calc(14)} / 1.4 $font-primary;
$modal-text-text-align: left;

//Subtitle
$modal-subtitle-font: #{rem-calc(18)} / 1.11 $font-secondary;
$modal-subtitle-font--large: $modal-subtitle-font;
$modal-subtitle-margin--large: rem-calc(0 0 17);
$modal-title-margin: rem-calc(0 26 6 0);
$modal-title-margin--large: rem-calc(0 15 15 0);
$modal-image-content-padding--large: rem-calc(80 70 80 100);
$modal-image-padding: 0;
$modal-image-content-padding: rem-calc(30 25);

$modal-wrapper-padding: rem-calc(60 25 20);

// Header
$modal-header-text-align: center;
$modal-header-text-align--large: $modal-header-text-align;

// Message
$modal-message-text-align: left;

$modal-image-width--large: rem-calc(400);
$modal-image-content-padding--large: rem-calc(80 60 50);
$modal-title-color: color(primary);
$modal-title-color--large: $modal-title-color;
$modal-title-text-align--large: left;
$modal-subtitle-color: color(primary);
$modal-image-max-width--large: rem-calc(970);

@import "@lora/05-components/modal";