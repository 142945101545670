$header-hamburger-button-width: rem-calc(65);
$header-hamburger-button-active-background: color(primary);
$header-hamburger-button-padding: rem-calc(20 10 21);
$header-hamburger-account-menu-icon-size: rem-calc(21 20);
$header-hamburger-item-link-transform: none;
$header-hamburger-item-link-padding: rem-calc(10 0 4);
$header-hamburger-storeslink-icon-size: rem-calc(20 30);
$header-hamburger-icon-height: rem-calc(36);
$header-hamburger-icon-width: rem-calc(36);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(21 23);
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(20 20);
$header-hamburger-item-icon-color: color(dark);
$header-hamburger-position: left;

@import "@lora/05-components/header/header-hamburger";