$header-suggestions-title-font: bold #{rem-calc(14)} / 1 $font-secondary;
$header-suggestions-title-font--large: #{rem-calc(26)} / 1 $font-secondary;
$header-suggestions-title-text-transform--large: uppercase;
$header-suggestions-title-text-transform: uppercase;
$header-suggestions-title-color: color(primary);
$header-suggestions-title-color--large: color(primary);
$header-suggestions-products-name-color: color(primary);
$header-suggestions-products-name-color--large: $header-suggestions-products-name-color;
$header-suggestions-products-description-margin: rem-calc(11 0 5);
$header-suggestions-products-name-text-transform--large: uppercase;
$header-suggestions-products-price-font: #{rem-calc(16)} / 1.25 $font-primary;
$header-suggestions-products-name-font--large: #{rem-calc(14)} / 1 $font-secondary;
$header-suggestions-products-description-color: color(organism-background);
$header-suggestions-products-description-font: #{rem-calc(12)} / 1 $font-primary;
$header-suggestions-view-all-color: color(dark);
$header-suggestions-products-name-max-lines: 3;
$header-suggestions-view-all-font: #{rem-calc(12)} / 1 $font-primary;
$header-suggestions-view-all-text-transform: none;
$header-suggestions-view-all-right: rem-calc(16) !default;
$header-suggestions-view-all-top: rem-calc(19) !default;
$header-suggestions-view-all-top--large: rem-calc(48) !default;
$header-suggestions-sections-separator: 1px solid color(border);
$header-suggestions-text-color: color(organism-background);
$header-suggestions-text-font--large: #{rem-calc(14)} / 1 $font-primary;
$header-suggestions-text-separator: 0;
$header-suggestions-text-separator--large: $header-suggestions-sections-separator;
$header-suggestions-text-list-separator--large: none;
$header-suggestions-text-height: rem-calc(20);
$header-suggestions-text-height--large: rem-calc(68);
$header-suggestions-products-padding--large: rem-calc(42 0 40);
$header-suggestions-products-item-padding--large: rem-calc(20 0 0 30);
$header-suggestions-products-height: rem-calc(20);
$header-suggestions-title-text-transform--large: capitalize;
$header-suggestions-title-padding: rem-calc(20 0 12);
$header-suggestions-products-separator: none;
$header-suggestions-products-item-padding: rem-calc(6 0);

$header-suggestions-products-link-padding: 0;
$header-suggestions-text-link-padding: rem-calc(14 0);

@import "@lora/05-components/header/header-suggestions";