$form-select-label-float-margin: rem-calc(0 18);
$form-select-border-radius: 0;
$form-select-border-width: rem-calc(1);
$form-select-border-color: color(border);
$form-select-height: rem-calc(50);
$form-select-padding: rem-calc(0 32 0 12);
$form-select-border-color-hover: color(primary-active);
$form-select-font-weight: 500;
$form-select-font-weight-hover: $form-select-font-weight;
$form-select-panel-item-background-hover: color(primary-active);
$form-select-panel-item-background-selected: color(primary-active);
$form-select-panel-item-color-hover: color(light);
$form-select-panel-item-color-selected: color(light);
$form-select-size: (
    "small": rem-calc(50),
    "normal": rem-calc(50),
    "large": rem-calc(50)
);
$form-select-font-sizes: (
    "small": rem-calc(12),
    "normal": rem-calc(12),
    "large": rem-calc(12)
);
$form-select-font-size: rem-calc(12);

@import "@lora/05-components/form/select";