$tabs-tab-font: #{rem-calc(14)} / 1 $font-secondary;
$tabs-tab-font--large: #{rem-calc(26)} / 1 $font-secondary;
$tabs-tab-active-font--large: $tabs-tab-font--large;
$tabs-tertiary-tab-font: #{rem-calc(14)} / 1 $font-secondary;
$tabs-tertiary-tab-font--large: #{rem-calc(14)} / 1 $font-tertiary;
$tabs-tertiary-tab-color: color(primary);
$tabs-tab-color: color(dark);
$tabs-tab-hover-color: color(primary-active);
$tabs-secondary-tab-hover-color: color(primary-active);
$tabs-tab-active-color: color(primary-active);
$tabs-tab-active-after-border-bottom: 1px solid color(primary-active);
$tabs-secondary-control-items-border-bottom: 1px solid color(border);
$tabs-control-items-border-bottom: 1px solid  color(border);
$tabs-secondary-controls-align-self: stretch;
$tabs-tab-text-transform: uppercase;
$tabs-tab-text-transform--large: none;
$tabs-tab-after-border-bottom: 1px solid color(border);
$tabs-tab-after-width: calc(100% + 1px);
$tabs-tab-margin: rem-calc(0);
$tabs-tab-margin--large: rem-calc(0 5);
$tabs-secondary-tab-padding: rem-calc(16 45 16 0);
$tabs-secondary-tab-padding--large: rem-calc(16 20 16 0);
$tabs-secondary-tab-font-size: rem-calc(14);
$tabs-secondary-tab-font-size--large: rem-calc(18);
$tabs-secondary-content-padding: rem-calc(50 20 30);
$tabs-secondary-controls-background: transparent;
$tabs-secondary-content-font: 300 #{rem-calc(14)} / 1 $font-primary;
$tabs-secondary-content-font--large: 300 #{rem-calc(14)} / 1 $font-primary;
$tabs-secondary-tab-font-family: $font-tertiary;
$tabs-control-items-margin: rem-calc(0);

@import "@lora/05-components/tabs";