$sidebar-navigation-title-font: #{rem-calc(26)} / 1 $font-secondary;
$sidebar-navigation-title-font--large: #{rem-calc(12)} / 1 $font-primary;
$sidebar-navigation-title-text-align: center;
$sidebar-navigation-title-text-align--large: left;
$sidebar-navigation-title-text-transform: capitalize;
$sidebar-navigation-title-text-transform--large: uppercase;
$sidebar-navigation-wrapper-active-padding: rem-calc(52 0 0);
$sidebar-navigation-title-margin: rem-calc(0 0 25);
$sidebar-navigation-links-padding: rem-calc(0 25 96);
$sidebar-navigation-links-padding--large: rem-calc(20 20 32);

@import "@lora/05-components/sidebar-navigation";